<template>
	<router-link
		:to="route"
		class="flex flex-wrap p-3 mb-3 bg-white border rounded-lg shadow-md"
	>
		<div class="grid flex-wrap w-full grid-cols-1 md:flex">
			<img
				:src="strain.strain_image === '' ? DEFAULT_LEAF_IMAGE : image"
				alt="Strain Image"
				class="mr-4 border rounded-lg"
				height="200"
				width="200"
			>
			<div class="flex flex-col justify-between pt-2 text-left">
				<h4 class="text-xl font-bold">
					{{ strain.strain_name }}
				</h4>
				<div class="text-gray-500 uppercase">
					{{ strain.strain_type }}
				</div>
			</div>
		</div>
	</router-link>
</template>

<script async>
import { mapActions, mapGetters } from 'vuex'

import { DEFAULT_LEAF_IMAGE } from '@/constants/search/defaultImage.js'

export default {
	props: {
		strain: {
			type: Object,
			default: () => ({})
		},
		type: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			DEFAULT_LEAF_IMAGE
		}
	},
	computed: {
		route() {
			return { name: 'strainIndividual', params: { strain: this.strain.seo_url } }
		},
		strainId() {
			return {
				strain_id: this.strain.strain_id
			}
		},
		seo() {
			return this.strain.seo_url
		},
		...mapGetters('auth', [
			'auth'
		]),
		image () {
			return this.strain.strain_image && this.strain.strain_image.replace('full', '200x200')
		}
	},
	methods: {
		handleAddToWishlist() {
			this.wishlistAddStrain(this.strainId)
		},
		...mapActions('user', [
			'wishlistAddStrain'
		])
	}
}
</script>
